<template>
    <div>
        <nav-bar title="年卡查询" url="/"></nav-bar>

        <!--用户信息-->
        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="data.name" label="真实姓名" placeholder="请输入真实姓名">
                </van-field>
                <van-field label-width="70" label-class="label" v-model="data.sfz" label="身份证号" placeholder="请输入身份证号">
                </van-field>
            </van-cell-group>
        </div>

        <div class="action">
            <van-button round type="primary" @click="submit" color="#00afff" size="large" :color="config.main_color"
                :disabled="!data.name && !data.sfz ?true:false">提交查询</van-button>
        </div>


        <div v-if="info.personName">
            <div class="top">
                <div class="name">
                    <span style="left: 15px;">年卡详情</span>
                </div>
                <div class="list">
                    <div class="left">姓名</div>
                    <div class="right">{{info.personName}}</div>
                </div>
                <div class="list">
                    <div class="left">证件编号</div>
                    <div class="right">{{info.certNo}}</div>
                </div>

                <div class="list">
                    <div class="left">年卡类型</div>
                    <div class="right">{{info.cardProName}}</div>
                </div>

                <div class="list">
                    <div class="left">开始时间</div>
                    <div class="right">{{info.beginDate}}</div>
                </div>

                <div class="list">
                    <div class="left">过期时间</div>
                    <div class="right">{{info.endDate}}</div>
                </div>
                <div class="list" @click="preview([info.qr])">
                    <div class="left">电子年卡</div>
                    <div class="right">
                        <van-image style="height: 30px;width: 40px;" fit="cover" :src="info.qr">
                        </van-image>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        user_query
    } from '@/api/user.js';
    import {
        ImagePreview,
        Notify,
        Dialog
    } from 'vant';
    export default {
        name: 'query',
        data() {
            return {
                loading: false,
                showPicker: false,
                info: {},
                cate: [],
                data: {
                    name: null,
                    sfz: null,
                    cate_id: null,
                },
            }
        },
        mounted() {
        },
        methods: {

            //图片预览
            preview(img, index) {
                ImagePreview({
                    images: img,
                    startPosition: index ? index : 0
                });
            },

            onConfirm(value) {
                this.data.cate_name = value;
                this.showPicker = false;
            },

            //提交数据
            submit() {
                var that = this;
                if (!this.data.name) {
                    Notify({ type: 'danger', message: '办理人姓名不得为空!' });
                    return false;
                }

                if (!this.data.sfz) {
                    Notify({ type: 'danger', message: '办理人身份证号码不得为空!' });
                    return false;
                }

                if (this.data.sfz.length !== 18) {
                    Notify({ type: 'danger', message: '办理人身份证号码长度不合法!' });
                    return false;
                }

                var data = {
                    name: this.data.name,
                    sfz: this.data.sfz,
                    cate_name: this.data.cate_name,
                };
                this.loading = true;
                user_query(data).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        if (!res.data.personName) {
                            Dialog.alert({
                                title: '提示',
                                message: '信息不存在！',
                            });
                        } else {
                            this.info = res.data;
                        }
                    }
                });

            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .top .name {
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        padding-right: 10px;
        font-weight: 800;
        font-size: 15px;
        width: calc(100% - 40px);
        position: relative;
        color: #060920;
    }

    .top .name img {
        height: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .top .name span {
        position: absolute;
        left: 35px;
    }


    .top .name::after {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        border-bottom: 1px solid #f2f2f2;
        pointer-events: none;
    }

    .top .list {
        position: relative;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 200;
        font-size: 13px;
        width: calc(100% - 20px);
    }

    .top .list .left {
        position: absolute;
        left: 10px;
        display: inline-block;
        font-weight: 700;
        color: #060920;
    }

    .top .list .right {
        position: absolute;
        right: 10px;
        display: inline-block;
        color: #060920;
        font-weight: 400;
        width: calc(100% - 70px);
        white-space: nowrap;
        text-align: right;
        height: 40px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .top .list::after {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: scale(0.5);
        transform-origin: 0 0;
        border-bottom: 1px solid #f2f2f2;
        pointer-events: none;
    }

    .top .list:last-child::after {
        border: none;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .van-button--primary {
        background-image: url('../../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }
</style>